<template>
    <v-container
        fluid
        grid-list-md>
        <v-layout>
            <v-flex
                xs12
                md8
                offset-md2
                mt-5>
                <v-card class="wrap-content gray-icons">
                    <v-toolbar color="transparent">
                        <v-toolbar-title class="light-blue--text title-block">
                            User
                        </v-toolbar-title>
                        <v-spacer />
                        <v-tooltip
                            v-model="deleteUser"
                            top>
                            <v-btn
                                slot="activator"
                                icon
                                @click="deleteModal = true">
                                <v-icon>delete</v-icon>
                            </v-btn>
                            <span>Delete User</span>
                        </v-tooltip>
                    </v-toolbar>
                    <v-dialog v-model="deleteModal">
                        <v-card>
                            <v-card-title>
                                <div class="headline">
                                    Delete {{ user.name }}
                                </div>
                            </v-card-title>
                            <v-card-text>Are you sure you want to delete {{ user.name }}?</v-card-text>
                            <v-card-actions>
                                <v-spacer />
                                <v-btn
                                    color="indigo darken-4"
                                    @click="deleteModal = false">
                                    Cancel <v-icon
                                        right
                                        dark>
                                        cancel
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    color="indigo darken-4"
                                    @click="removeUser()">
                                    Delete <v-icon
                                        right
                                        dark>
                                        delete
                                    </v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-list>
                        <v-list-tile
                            v-clipboard:copy="user.name"
                            v-clipboard:success="onCopy"
                            v-clipboard:error="onError"
                            avatar
                            @click.prevent>
                            <v-list-tile-avatar>
                                <v-icon>person</v-icon>
                            </v-list-tile-avatar>
                            <v-list-tile-content>
                                <v-list-tile-title>Name</v-list-tile-title>
                                <v-list-tile-sub-title>{{ user.name }}</v-list-tile-sub-title>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile
                            v-clipboard:copy="user.email"
                            v-clipboard:success="onCopy"
                            v-clipboard:error="onError"
                            avatar
                            @click.prevent>
                            <v-list-tile-avatar>
                                <v-icon>email</v-icon>
                            </v-list-tile-avatar>
                            <v-list-tile-content>
                                <v-list-tile-title>Email</v-list-tile-title>
                                <v-list-tile-sub-title>{{ user.email }}</v-list-tile-sub-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </v-list>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import ApiQuery from '../../api-query';

export default {
    title: 'User',
    data() {
        return {
            userId: null,
            user: [],
            errors: null,
            deleteUser: false,
            deleteModal: false,
            response: []
        };
    },
    created() {
        this.getUserData();
    },
    methods: {
        getUserData() {
            (new ApiQuery('users/' + this.$route.params.id))
                .get()
                .then(response => {
                    this.user = response.data.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        removeUser() {
            if (window.getUser().id != this.$route.params.id) {
                (new ApiQuery('users/' + this.$route.params.id))
                    .delete()
                    .then(response => {
                        this.response = response.data.data;
                        this.deleteModal = false;
                        this.$router.push('/users/all');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            } else {
                this.$flash('You can\'t delete yourself!', 'red');
            }
        },
        onCopy(e) {
            this.$flash('\''+e.text+'\' copied to clipboard!', 'green');
        },
        onError() {
            this.$flash('Failed to copy text.', 'red');
        }
    }
};
</script>
